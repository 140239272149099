.root {
  width: 120px;
  height: 130px;
  text-align: center;
}

.root svg {
  width: 65%;
  margin: 0 auto;
}

.title {
  background-color: var(--cui-background-color);
  padding: 3px;
  font-size: 90%;
}

.hiddenOpacity {
  opacity: 0.45;
}

.svgOpacity svg {
  opacity: 0.45;
}

.actionButtonsWrapper {
  display: none;
  position: absolute;
  bottom: 10px;
  margin: auto;
  width: 100%;
  z-index: var(--tb-diagram-z-index-edit);
}

.root:hover .actionButtonsWrapper {
  display: block;
}
