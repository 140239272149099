.root {
  display: flex;
  margin: 24px 0 24px 0;
}

.icon {
  font-size: 24px;
  width: 30px;
}

.text {
  font-size: small;
  flex: 1;
}
